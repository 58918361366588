<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                <div class="card-icon">
                    <md-icon>link</md-icon>
                </div>
                <h4 class="title">Jeju 4·3 Link</h4>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout">
                        <md-field class="edit-form md-layout-item md-size-15">
                            <label>파일 선택</label>
                            <md-file @change="onChangeFile($event)" accept="image/*" />
                        </md-field>
                        <md-field class="md-layout-item md-size-15">
                            <label>Title</label>
                            <md-input v-model="title"></md-input>
                        </md-field>
                        <md-field class="link-input md-layout-item md-size-50">
                            <label>Link</label>
                            <md-input v-model="link"></md-input>
                        </md-field>
                        <div class="md-layout-item md-size-10">
                            <md-button @click="postLink" class="md-dense md-raised md-primary">저장</md-button>
                        </div>
                    </div>
                    <md-table v-model="linkList" class="link-table" table-header-color="green">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="No">{{ item.index + 1 }}</md-table-cell>
                            <md-table-cell md-label="Thumbnail">
                                <img class="item-thumb" :src="item.metaData.thumbnailUrl" />
                                <md-field class="item-thumb">
                                    <label>파일 선택</label>
                                    <md-file @change="onChangeFile($event, item.index)" accept="image/*" />
                                </md-field>
                            </md-table-cell>
                            <md-table-cell md-label="Title">
                                <md-field>
                                    <label>Title</label>
                                    <md-input v-model="item.title"></md-input>
                                </md-field>
                            </md-table-cell>
                            <md-table-cell md-label="Link">
                                <md-field>
                                    <label>Link</label>
                                    <md-input v-model="item.link"></md-input>
                                </md-field>
                            </md-table-cell>
                            <md-table-cell md-label="Update">
                                <a href="javascript:void(0);" @click="updateLink(item.index)" class="updateBtn">update</a>
                            </md-table-cell>
                            <md-table-cell md-label="Delete">
                                <a href="javascript:void(0);" @click="deleteLink(item._id)" class="delBtn">delete</a>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import api from "@/commons/apiUrl";

export default {
    data() {
        return {
            linkList: [],
            title: "",
            link: "",
            thumbnail: null
        }
    },
    async created() {
        const res = await axios.get(api.GET_ALL_LINK());
        if(!res.data.success) {
            return;
        }

        this.linkList = res.data.data;
        this.linkList.forEach((element, index) => {
            element.index = index;
            element.metaData.thumbnailUrl = process.env.VUE_APP_API_BASE_URL + "/" + element.metaData.thumbnailUrl;
        });
    },
    methods: {
        async deleteLink(linkId) {
            const result = confirm("링크를 삭제하시겠습니까?");
            if(!result) return;

            const res = await axios.delete(api.DELETE_LINK(linkId),
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                }
            );
            if(!res.data.success) {
                this.$store.dispatch("alerts/error", "삭제 실패");

                return;
            }

            const delIndex = this.linkList.findIndex(item => {
                return item._id === linkId;
            });
            this.linkList.splice(delIndex, 1);

            this.$store.dispatch("alerts/success", "삭제 성공");
        },
        async postLink() {
            if(this.title === "" || this.link === "" || !this.thumbnail) {
                this.$store.dispatch("alerts/error", "thumbnail/title/link 모두 입력 후 저장해 주세요.");
                
                return;
            }

            const regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
            if(!regex.test(this.link)) {
                this.$store.dispatch("alerts/error", "유효하지 않은 주소입니다.\n http://www.kf.com과 같이 입력해 주세요.");
                
                return;
            }

            const data = new FormData();
            data.append("title", this.title);
            data.append("link", this.link);
            data.append("data", this.thumbnail);

            const res = await axios.post(api.POST_LINK(),
                data,
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                }
            );

            if(!res.data.success) {
                this.$store.dispatch("alerts/error", "저장 실패");

                return;
            }

            const saveData = res.data.data;
            saveData.metaData.thumbnailUrl = process.env.VUE_APP_API_BASE_URL + "/" + saveData.metaData.thumbnailUrl;
            saveData.index = this.linkList.length;
            this.linkList.push(saveData);

            this.$store.dispatch("alerts/success", "저장 성공");
        },
        onChangeFile(e, index=-1) {
            if(index === -1) {
                this.thumbnail = e.target.files[0];

                return;
            }

            const link = this.linkList[index];
            link.data = e.target.files[0];
            link.metaData.thumbnailUrl = URL.createObjectURL(e.target.files[0]);
        },
        async updateLink(index) {
            const result = confirm("링크를 수정하시겠습니까?");
            if(!result) return;

            const link = this.linkList[index];
            
            if(link.title === "" || link.link === "") {
                this.$store.dispatch("alerts/error", "title/link 모두 입력 후 저장해 주세요.");
                
                return;
            }

            const regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
            if(!regex.test(link.link)) {
                this.$store.dispatch("alerts/error", "유효하지 않은 주소입니다.\n http://www.kf.com과 같이 입력해 주세요.");
                
                return;
            }

            const data = new FormData();
            data.append("title", link.title);
            data.append("link", link.link);
            link.data ? data.append("data", link.data) : null;

            const res = await axios.put(api.UPDATE_LINK(link._id),
                data,
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                }
            );

            if(!res.data.success) {
                this.$store.dispatch("alerts/error", "수정 실패");

                return;
            }

            this.$store.dispatch("alerts/success", "수정 성공");
        }
    }
}
</script>

<style scoped>
.delBtn {
    color: red !important;
}
.updateBtn {
    color: blue !important;
}
.link-input {
    margin-left: 2rem;
}
.link-table {
    margin-top: 2rem;
}
.edit-form {
    margin-right: 1rem;
}
.item-thumb {
    max-width: 100px;
}
</style>